//
// IMPORTS
//

@import "reset";
@import "variables";
// Syntax highlighting @import is at the bottom of this file

/**************/
/* BASE RULES */
/**************/

html {
    font-size: 100%;
    font-family: Sans-serif;
    height: 100%;
}

body {
    background: $white;
    color: $darkGray;
    min-height:100%; 
    padding:0; 
    margin:0; 
    position:relative;
}

body::after{
    content:'';
    display:block;
    height:155px;
}

.container {
    margin: 0 auto;
    max-width: 840px;
    padding: 0 10px;
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    color: $darkerGray;
    font-weight: bold;
    line-height: 1.5;
    font-weight: normal;
    margin: 1em 0 15px;
    padding: 0;

    @include mobile {
        line-height: 1.4;
    }
}

h1 {
    font-size: 1.5em;

    a {
    color: inherit;
    }
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 18px;
    color: $gray;
}

.page h1 {
    margin-bottom: 1em;
    border-bottom: 1px solid #ddd;
}

.entry h1, .entry h2, .entry h3, .entry h4, .entry h5 {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1em;
    margin-top: 1em;
}

p {
    font-size: 14px;
    margin: 15px 0;
    line-height: 1.5em;
}

a {
    color: #337AB7;
    text-decoration: none;
	cursor: pointer;

    &:hover, &:active {
        color: #337AB7;
        text-decoration: underline;
    }
}

ul, ol {
    margin: 15px 0;
    padding-left: 30px;
}

ul {
    list-style-type: disc;
    font-size: 14px;
}

.entry li {
    font-size: 14px;
    margin: 0px;
    line-height: 1.5em;
}

.entry h2 {
    font-size:18px;
}

.entry hr {
    border-size: 10px;
    color: red;
}

hr {
    background: #ddd;
    border: 0;
    height: 1px;
    margin: 2em 0 0 0;
}

li {
    margin: 5px 0px 0px 0px;
}

ol {
    list-style-type: decimal;
}

ol ul, ul ol, ul ul, ol ol {
    margin: 0;
}

ul ul, ol ul {
    list-style-type: circle;
}

em, i {
    font-style: italic;
}

strong, b {
    font-weight: bold;
}

img {
    max-width: 100%;
}

// Fixes images in popup boxes from Google Translate
.gmnoprint img {
    max-width: none;
}

.date {
    font-style: normal;
    font-size:14px;
    color: $gray;
}

.post-date {
    font-style: normal;
    font-size:14px;
    color: $gray;
    margin-bottom: -20px;
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

//
// .masthead
//

.wrapper-masthead {
    background: #333;
    background-size: cover;
    margin-bottom: 2em;
    padding-bottom: 15px;
    //box-shadow: inset -4px -29px 23px -18px rgba(0,0,0,1);
    box-shadow: 0px 20px 50px 10px rgba(0,0,0,0.10);
    border-bottom: 5px solid #0088CC;
    
    @include mobile {
        margin-bottom: 1.5em;
    }
}

.masthead {
  padding: 5px 0;
  border-bottom: 0px solid $lightGray;

  @include mobile {
    text-align: center;
  }
}

.site-avatar {
    float: left;
    width: 100px;
    height: 100px;
    margin: 31px 10px 0px 0px;
    
    @include mobile {
        float: none;
        display: block;
        margin: 0 auto;
  }

    img:active {
        position:relative;
        top:2px;
        -webkit-transition-duration: 0.1s;
    }

    img {
        border-radius: 0px;
        outline: none;
    }
}

.site-logo {
    img{
        max-width: 200px;
        }  
}

.site-info {
    float: left;
    margin-top: 20px;

    @include mobile {
        float: none;
        display: block;
        margin: 0 auto;
    }

    img:active {
        position:relative;
        top:2px;
        -webkit-transition-duration: 0.1s;
    }
}

.site-name {
    margin: 0;
    color: #ffffff;
    cursor: pointer;
    font-family: Sans-Serif;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 1px;
    text-shadow: 0px 1px 2px #4D665A;

    a {
        color: #ffffff;
    }

    a:hover {
        color: #9ED4BB;
        text-decoration: none;
        text-shadow: 0px 1px 2px #4D665A;
    }
}

.site-description {
    margin: -5px 0 0 8px;
    color: #ffffff;
    font-size: 15px;
    text-shadow: 1px 1px 0px #5d7b6d;
    a {
        color: #ffffff;
    }

    @include mobile {
        margin: 3px 0;
    }
}

nav {
    float: right;
    margin-top: 1.55em;
    font-size: 18px;
    font-family: Sans-serif;
    
    @include mobile {
        float: none;
        margin-top: 9px;
        display: block;
        font-size: 12px;
    }

    a {
        display: inline-block;
        margin: 18px 20px 0px 3px;
        border:1px solid #333;
        color: #999;
        width: auto;
        height: auto;
        font-size: 14px;
        font-weight: bold;

        @include mobile {
            margin: 0 10px;
            color: #FFF;
            padding: 10px 10px;
        }

    }
    a:hover {
        display: inline-block;
        color: #FFF;
        text-decoration: none;
        transition: color .25s linear, background-color .25s ease-in-out, border-color .15s ease-in-out;
        }

    a:active {
        top:2px;
        transition-duration: 1.5s;
        box-shadow: 0 0 1px transparent;
        transform: translateY(2px);
    }
}

nav .current {
    color: #FFF;
}

.site-info {
    color: #FFF;
}

//
// .main
//

.latest {
    font-size: 1.5em;
    margin: 20px 0 20px 0;
    border-bottom: 1px solid #EEE;
    a {
    color: #222;
    }
    h2 {
    line-height: 0em;
    }
    .rss-icon {
    margin-top: -8px;
    }
}

.archive {
    a {
    font-size: 14px;
    color: #07B;
    }
    h2 {
    color: #222;
    border-bottom: 1px solid #EEE;
    margin-bottom: 25px;
    }
    p {
    line-height: 10px;
    margin: 9px 0px 0px 0px;
    padding: 0px 0px 8px 0px;
    border-bottom: 1px solid #EEE;
    }
    .read-more {
    margin-top: 23px;
    border-bottom: 0;
    }
    .rss-icon {
    margin-top: 16px;
    }
}
.posts > .post {
    padding-bottom: 1.2em;
    border-bottom: 1px solid $lightGray;
    margin-bottom: 20px;
}

.posts > .post:last-child {
    padding-bottom: 1em;
    border-bottom: none;
}

.post {
    blockquote {
        margin: 1.8em .8em;
        border-left: 2px solid $gray;
        padding: 0.1em 1em;
        color: $gray;
        font-style: italic;
    }
  
    .post h1 {
        font-size: 1.9em;
        text-decoration: none;
        margin: 24px 0px 0px;
    }

    .comments {
        margin-top: 10px;
    }

    .read-more {
        text-transform: normal;
        font-size: 14px;
        margin-right: 10px;
    }

}

.post h1 {
    font-size: 18px;
    color: #07B;
}

.wrapper-footer {
    margin-top: 50px;
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    background-color: $lightGray;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05) inset;
    position:absolute;
    bottom:0;
    width: 100%;
}

footer {
    padding: 30px 0;
    text-align: center;
    font-size: 14px;
  
    p {
        font-family: Sans-serif;
        font-size: 14px;
        margin: 10px 0px -5px 0px;
        text-shadow: 0 1px 0 #F2F2F2;
    }
}

// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";

.post-meta {
    font-size: 14px;
    color: #828282;
}

.post-comments {
    font-size: 14px;
    color: #828282;
    float: right;
    list-style-type: none;
}

.post-comments a:link, .post-comments a:visited {
     color: #828282;
}

.posts h1 {
    margin: 5px 0px -5px;
    text-decoration: none;
}

.post h2, .post h3 {
    margin-top: 25px;
}

.tags, .tags-page, .tags-cloud {
    list-style: none;
    margin-bottom: 30px;
    overflow: hidden; 
    padding: 0px 0px 0px 0px;
}

.tags-cloud {
    padding: 0px;
}


#main .tags {
    border-bottom: solid 1px #eee;
    padding-bottom: 20px;
}

.tags-page {
    border-top: solid 1px #6B1E69;
    border-color: #ccc;
    padding-top: 10px;
    margin: 2rem 0 0 0;
}

.tags li, .tags-page li, .tags-cloud li {
    float: left; 
}

.tag {
    background: #eee;
    background-image: linear-gradient(to top, #EEE, #F2F2F2);
    border-radius: 3px 0 0 3px;
    border: 1px solid #D1D1D1;
    color: #888;
    display: inline-block;
    height: 28px;
    line-height: 26px;
    padding: 0 12px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: color 0.3s;
    font-size: 10px;
    border-radius: 3px;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.20);
}

.tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
}

.tag::after {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
}

.tag:hover {
    background-color: #B994C6;
    background-image: linear-gradient(to top, #A685B2, #B994C6);
    border: 1px solid #9678A1;
    color: #FFF;
    text-decoration: none;
}

.tag:hover::after {
    border-left-color: #2EAE9B; 
}

.tag:hover a:visited {
    color: #999;
}

.tag:visited hover {
    color: #FFF;
}

.tags a:hover {
    color: #FFF;
}

.tags a:active {
        position:relative;
        top:2px;
        -webkit-transition-duration: 0.1s;
}

h1.post-title {
    font-size: 24px;
}

h2.tags-title  {
    margin-top: 30px;
    border-bottom: solid 1px #6B1E69;
    border-color: #ccc;
}

.tags-title {
    font-size: 24px;
}

.tags-date {
    font-size: 14px;
    color: #828282
}

.entry {
    p {
    margin-bottom: 15px;
    }
}

.entry img, .content img, .intro img {
    border-radius: 3px;
    box-shadow: 0 2px 3px #CCC;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    border: 1px solid #777;
    padding: 1px;
    background: #ccc;
}

.entry:visited img, .content:visited img, .entry a:visited img, .content a:visited img {
    border: 1px solid #777;
}

.highlighter-rouge {
	border-radius: 2px;
	border: 1px solid #DDD;
	padding: 2px 4px;
	background: #EEE;
	font-size: 12px;
	margin-bottom: 10px;
}

.highlighter-rouge {
    .highlight {
        border: 0px;
        overflow: auto;
    }
}

.PageNavigation {
    font-size: 14px;
    display: block;
    width: auto;
    overflow: hidden;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    border-top: 1px solid #eee;
}

.PageNavigation a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
}

.PageNavigation .next {
    text-align: right;
    float: right;
}

.pagination, .previous, .next {
    font-size: 14px;
}

.pagination {
    text-align: center;
    margin-top: -10px;
}

.previous {
    float: left;
}

.next {
    float: right;
}

.page_number {
    width: 50%;
}

.related-posts {
    border-bottom: 1px solid #CCC;
    padding: 0 0 30px 0;
}

.related-posts h2 {
    margin-top: 0;
}

.related-posts li {
    font-size: 14px;
    margin: 6px 0px 0px 20px;
}

.comments {
    padding: 0;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 1.5em;
}

.red-note {
    border-radius: 2px;
    border: 1px solid #DBB;
    padding: 2px 4px;
    background: #FDD;
    font-size: 12px;
    margin-bottom: 10px;

    p {
        margin: 5px
    }
}

.comments h2 {
    margin-bottom: 0;
}

.single-comment {
    background: #F8F8F8;
    border: 1px solid #CCC;
    padding: 1em 1em 0 1em;
    border-radius: 2px;
    margin-top: 1em;
}

.comment-date {
    font-size: 12px;
    color: #555;
}

.comment-compose {
    border-top: 1px solid #CCC;
    margin-top: 2em;
}

.comment-form {
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.comment-form p {
    padding: 0em;
    margin: 0.5em 0 0 0;
}

.comment-info {
    color: #555;
    font-size: 12px;
    margin: 0;
}

input{
    font-size: 14px;
    color: #333;
    width: 50%;
    padding: 0.5em;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #f8f8f8;
    box-sizing: border-box;
    outline: none;
}

textarea {
    font-size: 14px;
    color: #333;
    width: 100%;
    height: 150px;
    padding: 0.5em;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #f8f8f8;
    outline: none;
    margin: 8px 0 0 0;
}

button {
    background-color: #519C79;
    border: none;
    color: white;
    padding: 1.2em 1.8em;
    text-decoration: bold;
    font-weight: bold;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    box-shadow: 0 3px #448265;
    outline: none;
}

button:hover {
    background-color: #51839C;
}

button:active {
    background-color: #51839C;
    box-shadow: 0 3px #40677A;
    transform: translateY(2px);
}

.aboutbar {
    float: right;
    width: 190px;
    margin: 0em 0em 0.5em 1em;
    clear: all;
}

.aboutbar .avatar {
    box-shadow: none;
    margin: 0;
    border: 0px;
    background: #fff;
}

.aboutbar h3 {
    margin: 10px;
}

.aboutbar ul {
    margin: 10px 0;
}

.aboutbar li {
    font-size: 12px;
}

.back {
    float: right;
    margin-top: -4em;
    font-size: 12px;
    color: #888;
}

.highlight  {
    padding: 5px 5px 5px 9px;
}

.social-email, .social-mastodon, .social-rss {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    width: auto;
    height: auto;
    padding: 7px 14px;
}

.social-email {
    border:1px solid #ac4128;
    color: #FFF;
    box-shadow: inset 0 1px 0 0 #ec9682,inset 0 -1px 0 0 #ac4128,inset 0 0 0 1px #e76e52,0 2px 4px 0 #D4D4D4;
    text-shadow: 0 1px 0 #7e2f1d;
    background-color: #E45635;
}

.social-mastodon {
    border:1px solid #2474ac;
    color: #FFF;
    box-shadow: inset 0 1px 0 0 #88c2ea,inset 0 -1px 0 0 #2474ac,inset 0 0 0 1px #5faee5,0 2px 4px 0 #D4D4D4;
    text-shadow: 0 1px 0 #195783;
    background-color: #2B90D9;
}

.social-rss {
    border:1px solid #a46411;
    color: #FFF;
    box-shadow: inset 0 1px 0 0 #fbbf70,inset 0 -1px 0 0 #bf7616,inset 0 0 0 1px #fbaa42,0 2px 4px 0 #D4D4D4;
    text-shadow: 0 1px 0 #a46411;
    background-color: #F8991D;
}

.social-email:hover, .social-mastodon:hover, .social-rss:hover, .social-deviantart:hover {
    color: #FFF;
    text-decoration: none;
    background-image: linear-gradient(to top, rgba(255,255,255,0.25), rgba(255,255,255,0));

    &:active {
        transform: translateY(2px);
    }
}

.rss-icon {
    float: right;
}

.intro {
    margin-bottom: 3.5em;
}
